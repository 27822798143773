import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
// import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { JMT_TABS } from '../../components/jmt_tabs'; 
import { WEBCALL } from '../../components/webcall'; 

// import { JMT_FIELD_HEADER} from '../../components/jmt_field_header'
// import { JMT_FIELD_TEXTBOX} from '../../components/jmt_field_textbox'
// import { JMT_FIELD_TEXTAREA} from '../../components/jmt_field_textarea'
import { JMT_FIELD_BUTTON } from '../../components/jmt_field_button';
// import { JMT_FIELD_LABEL } from '../../components/jmt_field_label';

export const MESSAGES = ({ pageSetter, uid, profile_data }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [dataMessages, setDataMessages] = useState({});
    const [loadingMessages, setLoadingMessages] = useState(true);
    const [filter, setFilter] = useState("");

    // const [loadingAccStatus, setLoadingAccStatus] = useState(true);
    // const [dataAccStatus, setDataAccStatus] = useState({});

    // const [displayBasic_NewMessage, setDisplayBasic_NewMessage] = useState(false);
    const [displayBasic_ViewMessage, setDisplayBasic_ViewMessage] = useState(false);
    
    const [selectedTab, setSelectedTab] = useState("Inbox");
    const [messageType, setMessageType] = useState("Inbox");

    const [message, setMessage] = useState({});

    // const [subject, set_subject] = useState("");
    // const [messageBody, set_messageBody] = useState("");
    // const [value, set_value] = useState("");
    // const [label, set_label] = useState("");
    // const [firstname, set_firstname] = useState("");
    // const [surname, set_surname] = useState("");
    // const [accountnumber, set_accountnumber] = useState("");
    // const [status, set_status] = useState("");
    // const [LAST_MESSAGE_OPEN, set_LAST_MESSAGE_OPEN] = useState("");
    // const [rec_sent, set_rec_sent] = useState("");

    useEffect(() => {
        var msgType = 0;
        if(selectedTab!=="Inbox"){
            msgType = 1;
        };
        api_app_messages_get(msgType)
        // api_app_acc_status()
    }, [selectedTab]);

    // const api_app_acc_status = () => {
    //     WEBCALL("app_acc_status", setLoadingAccStatus, setDataAccStatus, setError, uid, {}, 
    //         (data) => {}, 
    //         (error) => {}
    //     );
    // };

    const api_app_messages_get = (message_type) => {
        WEBCALL("app_messages_get", setLoadingMessages, setDataMessages, setError, uid, {"sent": message_type}, 
            (data) => {}, 
            (error) => {}
        );
    };

    // const api_app_messages_send = () => {
    //     WEBCALL("app_messages_send", setLoading, setData, setError, uid, 
    //         {
    //             "subject": subject,
    //             "body": messageBody,
    //             // "messageType": [{ value, label }],                
    //             "firstname": dataAccStatus.firstname,
    //             "surname": dataAccStatus.surname,
    //             "user_account": dataAccStatus.accountnumber,
    //             "user_code": dataAccStatus.useruid,
    //         }, 
    //         (data) => {}, 
    //         (error) => {}
    //     );
    // };

    const api_app_messages_set = (status, message_uid) => {
        WEBCALL("app_messages_set", setLoading, setData, setError, uid, { "status": status, "uid": message_uid }, 
            (data) => {
                if(status==="DELETE"){
                    api_app_messages_get(messageType);
                }
            }, 
            (error) => {}
        );
    };

    const displayMessage = (message) => {
        setMessage(message);
        setDisplayBasic_ViewMessage(true);
        api_app_messages_set("MARKREAD", message.uid)
    };

    const deleteMessage = (message) => {
        setMessage(message);
        setDisplayBasic_ViewMessage(false);
        api_app_messages_set("DELETE", message.uid)
    };

    return (
        <JMT_LOADING_PANEL loading={loadingMessages} data={data} error={error}>
            {/* New Message */}
            {/* <Dialog 
                header="New Message" 
                visible={displayBasic_NewMessage} 
                style={{ width: '90vw', backgroundColor:'#dddddd'}} 
                onHide={() => setDisplayBasic_NewMessage(false)}
            >
                <JMT_FIELD_TEXTBOX id="subject" caption="Subject" value={subject} setter={set_subject} placeholder={""} />
                <JMT_FIELD_TEXTAREA id="messageBody" caption="Body" value={messageBody} setter={set_messageBody} placeholder={""}/>
                <JMT_FIELD_BUTTON caption={'Send Message'} onclick={() => api_app_messages_send(message.uid)}/>
            </Dialog>                 */}
            {/* View Message */}
            <Dialog 
                header={message.subject} 
                visible={displayBasic_ViewMessage} 
                style={{ width: '90vw' }} 
                onHide={() => setDisplayBasic_ViewMessage(false)}
            >
                    <p>Sent: {message.senddate}</p>
                    <div dangerouslySetInnerHTML={{ __html: message?.body }} />
                    {/* delete does not fully work */}
                    <JMT_FIELD_BUTTON caption={'Delete Message'} onclick={() => deleteMessage(message)}/>
                    {/* <pre>data: {JSON.stringify(message, null, "\t")}</pre> */}
            </Dialog>        
            {/* Balance of Page */}
            <div className="col-12">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-search"></i>
                    </span>
                    <InputText 
                        id="filter" 
                        value={filter} 
                        placeholder="Filter" 
                        autoComplete="off"
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    {/* <Button label="New" icon="pi pi-external-link" onClick={() => setDisplayBasic_NewMessage(true)}/> */}
                </div>
            </div>
            <JMT_TABS data={["Inbox", "Broadcast"]} selected_tab={selectedTab} setter={setSelectedTab}/>
            <br/>
            <div className="card" >
                {dataMessages?.app_messages_getlist?.filter(item => item.body.includes(filter))?.map(message => (
                    <>
                        <div style={{ backgroundColor:'white', width:'100%', borderRadius: '15px', padding:'20px', overflow: 'hidden' }} onClick={() => displayMessage(message)}>
                            <div key={message.body}>
                                <span style={{fontSize:'1.2em', fontWeight:`${message.readflag === "0" ? 'bold' : 'normal'}`, marginBottom:'5px'}}>{message.subject}</span><br/>
                                <span style={{color:'#aaaaaa'}}>{message.senddate}</span>
                                <br/>
                                <span>{message?.body?.slice(0, 150)}...</span>
                            </div>
                        </div>
                        <br/>
                    </>
                ))}
            </div>
            {/* <pre>data: {JSON.stringify(dataAccStatus, null, "\t")}</pre> */}
            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}
