import React from 'react';


export const JMT_TABS = ({ data, selected_tab, setter }) => {

    return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {data.map((tab, index) => {
                return (
                    <div
                        key={tab}
                        style={{
                            flex: '1', 
                            margin: '5px', 
                            borderRadius: '5px',
                            color: 'white', 
                            backgroundColor: `${selected_tab === tab ? '#2196F3' : 'grey'}`, 
                            padding: '10px', 
                            textAlign: 'center'
                        }}
                        onClick={() => setter(tab)}
                    >
                        {tab}
                    </div>
                );
            })}
        </div>

    );
}

