import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const STORE_PAGE = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [STORE_PUID, set_STORE_PUID] = useState({});
    const [store_purchase_qty, set_store_purchase_qty] = useState({});

    const [puid, set_puid] = useState({});
    const [qty, set_qty] = useState(100);
    const [cuid, set_cuid] = useState({});

    useEffect(() => {
        api_app_storepage();
    }, []); 

    const api_app_storepage = () => {
        WEBCALL("app_storepage", setLoading, setData, setError, uid, {"qty": qty, "catid": profile_data}, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_add_cart = () => {
        WEBCALL("app_add_cart", setLoading, setData, setError, uid, {"productuid": STORE_PUID, "qty": store_purchase_qty}, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_cartlist = () => {
        WEBCALL("app_cartlist", setLoading, setData, setError, uid, {}, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_storepinfo = () => {
        WEBCALL("app_storepinfo", setLoading, setData, setError, uid, {"productid": puid}, 
            (data) => {}, 
            (error) => {}
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <div style={{ width:'100%', borderRadius: '15px', overflow: 'hidden' }}>
                <DataTable 
                    value={data?.app_storepagelist} 
                    showHeader={false} 
                    style={{ borderRadius: '15px' }}
                    // onRowClick={(e) => {pageSetter("STORE_PAGE", e.data.cuid);}} 
                >
                    <Column field="icon" body={(rowData) => {return <img width="50px" src={rowData.imagesmall}/>}}/>
                    <Column field="title" />
                    <Column field="notification"/>
                </DataTable>
            </div>
            <pre>data: {JSON.stringify(profile_data, null, "\t")}</pre>
            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}