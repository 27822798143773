import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 
import { JMT_FIELD_LABEL } from '../../components/jmt_field_label';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { JMT_FIELD_BUTTON } from '../../components/jmt_field_button';

export const EWALLETS = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [APP_WALLET_STATEMENT, setAppWalletStatement] = useState({});
    // const [acctype, setAcctype] = useState({});

    const [ewallet_transfer_number, setEwalletNumber] = useState({});

    const [lastid, set_APP_WALLET_SALES] = useState({});

    
    useEffect(() => {
        api_app_user_ewallets();
         api_app_wallet_statement();
         api_app_ewallet_transfer();
         api_app_wallet_sales();
    }, [    ]); 


    const api_app_user_ewallets = () => {
        WEBCALL("app_user_ewallets", setLoading, setData, setError, uid, {},
            (data) => {}, 
            (error) => {}
        );
    };
    const api_app_wallet_statement = () => {
        WEBCALL("app_wallet_statement", setLoading, setAppWalletStatement, setError, uid, 
            {
                "lastid": APP_WALLET_STATEMENT,
                // "acctype": acctype
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_ewallet_transfer = () => {
        WEBCALL("app_ewallet_transfer", setLoading, setEwalletNumber, setError, uid, 
            {
                "ewalletnumber": ewallet_transfer_number
                // amount: $('#ewallet_transfer_amount').val(),
                // pin: $('#ewallet_transfer_pin').val()
            },
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_wallet_sales = () => {
        WEBCALL("app_wallet_sales", setLoading, set_APP_WALLET_SALES, setError, uid, 
            {
                "lastid": lastid
            }, 
            (data) => {}, 
            (error) => {
                // Handle error here
                if (error.msg) {
                    // Optionally, you can display the error message to the user
                    console.error(error.msg); // Log it for debugging
                    setError(error.msg); // Set the error state
                }}
        );
    };



    // "service": "app_wallet_sales",
	// "result": "ok",
	// "app_wallet_saleslist": [
	// 	{

    // to bacome card element
	// 		"txid": "50",
	// 		"date": "12-11-2024 13:45",
	// 		"cost": "5.00",
	// 		"amount": "5.00",
	// 		"discount": "0.00",
	// 		"reference": "R5 Call-a-lot",
	// 		"description": "Top-Up:,amount=5.0"


	// "service": "app_wallet_statement",
	// "result": "ok",
	// "app_wallet_statementlist": [
	// 	{

    // to become card element
	// 		"txid": "1",
	// 		"date": "27/11/2024",
	// 		"reference": "Pending",
	// 		"amount": "R -2,160.00",
	// 		"balance": "R 0.00",
	// 		"symbol": "R",
	// 		"method": "DR",
	// 		"icon": "https://tspc-mobile.com/app/images/account/debit.png",
	// 		"txuid": "-",
	// 		"extref": "-"
	// 	},

    // "service": "app_user_ewallets",
	// "result": "ok",
	// "app_user_ewalletslist": [
	// 	{
	// 		"ewalletname": "FNBeWallet",
	// 		"icons": "https://apollo.iis-mssql.duckdns.org/app/images/fnb.png",
	// 		"ewalletnumber": "000000000000001",
	// 		"ewalletuid": "22DB144F-C276-4AF7-99E1-5DF332146C2D",
	// 		"regstatus": "NONE",
	// 		"mobile": "",
	// 		"requirespin": "0",
	// 		"ewalletname_1": "FNBeWallet"
	// 	}

    
    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <JMT_FIELD_HEADER title="eWallets"/>

            <div style={{ width:'100%', borderRadius: '15px', overflow: 'hidden' }}>
                <DataTable 
                    header={"USER INFO"}
                    // showHeaders={false}
                    value={data?.app_user_ewalletslist}     
                    style={{ borderRadius: '15px' }}
                >
                    <Column field="icon" body={(rowData) => {return <img width="50px" src={rowData.icons} />}}/>
                    <Column header={"Account Name"}body={(rowdata)=>{return <>{rowdata.ewalletname}<br/></>}}/>
                    <Column header={"Status"} body={(rowdata)=>{return <>{rowdata.regstatus}</>}}/>
                    
                    <Column header={"e-Wallet Name"} body={(rowdata)=>{return <>{rowdata.ewalletname_1}</>}}/>
                    <Column header={"e-Wallet Number"} body={(rowdata)=>{return <>{rowdata.ewalletnumber}</>}}/>
                </DataTable>
                <DataTable
                    showHeaders={false}
                    value={data?.app_user_ewalletslist}     
                    style={{ borderRadius: '15px' }}
                >
                <Column body={(rowdata)=>{return <>{"e-Wallet UID: "+rowdata.ewalletuid}</>}}/>
                </DataTable>
            </div>
            <div><JMT_FIELD_BUTTON caption={'View All'}  onclick={()=> pageSetter("TRANSACTIONS")}/></div>
            <br/>
            {/*  className="col-7" style={{padding:'10px'}} */}
            <div>
                <JMT_FIELD_HEADER title={"Transactions"}/>
                <div style={{ width:'100%', borderRadius: '15px', overflow: 'hidden' }}>
                    <DataTable 
                        header={"statementlist"}
                        // showHeaders={false}
                        value={APP_WALLET_STATEMENT?.app_wallet_statementlist} 
                        style={{ borderRadius: '15px' }}
                        >
                        <Column header={"Date"}body={(rowdata)=>{return <>{rowdata.date}</>}}/>
                        <Column header={"Refrence"}body={(rowdata)=>{return<>{rowdata.reference}</>}}/>
                        <Column header={"Amount"}body={(rowdata)=>{return <>{rowdata.amount}</>}}/>
                        <Column header={"Balance"}body={(rowdata)=>{return <>{rowdata.balance}</>}}/>
                    </DataTable>
                </div>
                <br/>


                <div style={{ width:'100%', borderRadius: '15px', overflow: 'hidden' }}>
                    <DataTable 
                        header={"Saleslist"}
                        // showHeaders={false}
                        value={lastid?.app_wallet_saleslist} 
                        style={{ borderRadius: '15px' }}
                    >

                        {/* <Column field="icon" body={(rowData) => {return <img width="50px" src={rowData.icons} />}}/>  */}
                        <Column header={"Date"} body={(rowData) => {
                        const formattedDate = new Date(rowData.date.replace(/-/g, '/')).toLocaleDateString('en-GB');
                        return <>{formattedDate}</>;}} />                        
                        <Column header={"Refrence"}body={(rowdata)=>{return<>{rowdata.reference}</>}}/>
                        <Column body={(rowdata)=>{return <>Cost:<br/>Dicount:</>}}/>
                        <Column body={(rowdata)=>{return <> R:{rowdata.cost}<br/>R:-{rowdata.discount}</>}}/>
                        <Column header={"Total"} body={(rowdata)=>{return <>R:{rowdata.amount}</>}}/>
                    </DataTable> 
                 </div>
            </div>
                        
        </JMT_LOADING_PANEL>
    );
}