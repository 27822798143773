import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const GAMES = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    
    const [gameid, set_gameid] = useState({});
    const [func, set_func] = useState({});
      

    useEffect(() => {
        api_app_reward_game(); 
        // api_app_reward_status();
        // api_app_reward_history();
    }, []); 
    
    const api_app_reward_game = () => {
        WEBCALL("app_reward_game", setLoading, setData, setError, uid, 
                {
                    "gameid":gameid,
                }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_reward_status = () => {
        WEBCALL("app_reward_status", setLoading, set_func, setError, uid, 
                {
                    "func":func,
                }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_reward_history = () => {
        WEBCALL("app_reward_history", setLoading, setData, setError, uid, {}, 
            (data) => {}, 
            (error) => {}
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>


            {/* <pre>data: {JSON.stringify(func, null, "\t")}</pre>
            <pre>data: {JSON.stringify(gameid, null, "\t")}</pre> */}
            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}