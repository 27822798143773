import React from 'react';
import { Carousel } from 'primereact/carousel';


export const JMT_CAROUSEL = ({ data }) => {

    return (
        <div className="carousel-container">
            <Carousel 
                value={data.filter(item => item.style === "B")} 
                numVisible={1} 
                numScroll={1} 
                circular
                showIndicators={false}
                showNavigators={false}
                autoplay
                autoplayInterval={2000} 
                itemTemplate={(product) => {
                    return (
                        <div className="carousel-image">
                            <img src={`${product.imagelarge}`} alt={product.name} className="carousel-img"/>
                        </div>
                    );
                }} 
            />
            <br/>
        </div>

    );
}

