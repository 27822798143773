import React from 'react';

export const JMT_ERROR = ({ loading, error }) => {

    return (
        <>
            {
                loading === true
                ?                
                <></>
                :
                    error !== ""
                    ?
                    <>
                        <h3>Error</h3>
                        <h4>{error}</h4>
                    </>
                    :
                    <></>
            }        
        </>
    );
}

