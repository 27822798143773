import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const ELECTRICITY = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [ELEC_TXUID, set_ELEC_TXUID] = useState({});



    useEffect(() => {
        api_app_store_txpoll(); 
    }, []); 

    const api_app_store_txpoll = () => {
        WEBCALL("app_store_txpoll", setLoading, set_ELEC_TXUID, setError, uid, 
            {
                "txuid": ELEC_TXUID
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <JMT_FIELD_HEADER title="Electricity"/>



            <pre>data: {JSON.stringify(ELEC_TXUID, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}