import React, { useState } from "react";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";

import { WEBCALL } from "../components/webcall";
import { JMT_LOADING_PANEL } from "../components/jmt_loading_panel";

export const JMT_PAGE_FOOTER = ({ title, uid, screen, pageSetter }) => {
    return (
        <footer className="footer">
            <div style={{ margin: "0px", position: "fixed",height:"65px", bottom: "0", width: "100%", display: "flex", justifyContent: "space-between" }}>
                <img src="images/footer/footer_vector/left_section.png" style={{ width: "auto", height: "70px", flexShrink: 0 }} />
                <img src="images/footer/footer_vector/inbetween_section.png" style={{ width: "100%", height: "70px", objectFit: "cover", flexGrow: 1 }} />
                <img src="images/footer/footer_vector/mid_section.png" style={{ width: "auto", height: "80px", flexShrink: 0 }} />
                <img src="images/footer/footer_vector/inbetween_section.png" style={{ width: "100%", height: "70px", objectFit: "cover", flexGrow: 1 }} />
                <img src="images/footer/footer_vector/right_section.png" style={{ width: "auto", height: "70px", flexShrink: 0 }} />
            </div>

{/* footer colors */}
            <div width="25%" style={{ margin: "0px", position: "fixed",height:"10px", bottom: "0", width: "100%", display: "flex", justifyContent: "space-between", zIndex:"2" }}>
                <div style={{ backgroundColor:'#047a37', width: "25%", height: "20px", flexShrink: 0,flexGrow: 1  }}></div>
                <div style={{ backgroundColor:"#0387c9", width: "25%", height: "20px", flexShrink: 0,flexGrow: 1  }}></div>
                <div style={{ backgroundColor:"#FFCB05", width: "25%", height: "20px", flexShrink: 0 ,flexGrow: 1 }}></div>
                <div style={{ backgroundColor:"#D4171F", width: "25%", height: "20px", flexShrink: 0,flexGrow: 1  }}></div>
            </div>
            
            {/* icon buttons */}
            <div style={{ position: "fixed", left: "0", bottom: "0px", width: "100%",height:'70px',bordertopleftradius: "20px", bordertoprightradius: "20px" }}>
                <br/>
                <table style={{ width: "100%", height: "auto", textalign: "center", fontfamily: "Poppins", fontweight: "400", borderCollapse: "collapse" }}>
                    <tr>
                        {/* Home Section */}
                        <td style={{ width: "20%" }}>
                            <span onClick={() => pageSetter("CART")} style={{ display: "inline-block",}}>
                                {screen === "CART" ? (
                                    <>
                                        <i className="pi pi-shopping-cart" style={{'fontSize': '1em'}}></i>
                                        <br />
                                        <b>Cart</b>
                                    </>
                                ) : (
                                    <>
                                        <i className="pi pi-shopping-cart" style={{'fontSize': '0.8em'}}></i>
                                        <br />
                                        Cart
                                    </>
                                )}
                            </span>
                        </td>

                        {/* Reports Section */}
                        <td style={{ width: "20%" }}>
                            <span onClick={() => pageSetter("REPORTS")} style={{ display: "inline-block", margintop: "10px" }}>
                                {screen == "REPORTS" ? (
                                    <>
                                        <i className="pi pi-chart-bar" style={{'fontSize': '1em'}}></i>
                                        <br/>
                                        <b>Reports</b>
                                    </>
                                ) : (
                                    <>
                                        <i className="pi pi-chart-bar" style={{'fontSize': '0.8em'}}></i>
                                        <br />
                                        Reports
                                    </>
                                )}
                            </span>
                        </td>

                        {/* Rica Section */}
                        <td>
                        <span onClick={() => pageSetter("RICA")} style={{ display: "inline-block", }}>
                            <div style={{ position: "absolute", top: "-8px", left: "50%", transform: "translateX(-50%)",height:"40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {screen === "RICA" ? (
                                    <>
                                        <img width="120px" src="/images/footer/footer_icons/rica.png"/>
                                    </>
                                ) : (
                                    <>
                                        <img width="100px" src="/images/footer/footer_icons/rica.png" />
                                        
                                    </>
                                )}
                            </div>
                        </span>
                        <br/>
                        <span style={{ display: "inline-block", lineHeight: '.8', fontWeight: screen === "RICA" ? "bold" : "normal"}}>RICA</span>
                        </td>

                        {/* Wallet Section */}
                        <td style={{ width: "20%" }}>
                            <span onClick={() => pageSetter("ACCOUNT")} style={{ display: "inline-block", margintop: "10px" }}>
                                {screen == "ACCOUNT" ? (
                                    <>
                                        <i className="pi pi-wallet" style={{'fontSize': '1em'}}></i>
                                        <br />
                                        <b>Accounts</b>
                                    </>
                                ) : (
                                    <>
                                        <i className="pi pi-wallet" style={{'fontSize': '0.8em'}}></i>
                                        <br />
                                        Accounts
                                    </>
                                )}
                            </span>
                        </td>

                        {/* Profile Section */}
                        <td style={{ width: "20%" }}>
                            <span onClick={() => pageSetter("SETTINGS")} style={{ display: "inline-block", margintop: "10px" }}>
                                {screen == "SETTINGS" ? (
                                    <>
                                        <i className="pi pi-cog" style={{'fontSize': '1em'}}></i>
                                        <br />
                                        <b>Settings</b>
                                    </>
                                ) : (
                                    <>
                                        <i className="pi pi-cog" style={{'fontSize': '0.8em'}}></i>
                                        <br />
                                        Settings
                                    </>
                                )}
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </footer>
    );
};
