import React from 'react';

export const JMT_TSPC_CATEGORY = ({ pageSetter, data }) => {
    const handleClick = (processtemplate, cuid) => {
        console.log(processtemplate)
        console.log(cuid)
        if(processtemplate == "NEWS"){pageSetter("NEWS", cuid)}
        if(processtemplate == "SURVEY"){pageSetter("SURVEY", cuid)}
        if(processtemplate == "EARNCOMMNOW"){pageSetter("EARN_COMM_NOW", cuid)}
        if(processtemplate == "GAMEOVER"){pageSetter("REWARDS", cuid)}
        if(processtemplate == "RICA"){pageSetter("RICA", cuid)}
        if(processtemplate == "REPORTS"){pageSetter("REPORTS", cuid)}
        if(processtemplate == "EXTURL"){pageSetter("TRAINING", cuid)}
        if(processtemplate == "ETOPUP"){pageSetter("ETOPUP", cuid)}
        // if(processtemplate == ""){pageSetter("")}
        if(processtemplate == ""){pageSetter("STORE_PAGE", cuid)}


    };

    return (
        <>
            {data.filter(item => item.style === "C").map(user => (
            // Use the `key` prop to uniquely identify each item
                <div className="col-3" onClick={() => handleClick(user.processtemplate, user.cuid)}>
                    <div width="100%" style={{height:"125px", backgroundColor:"#ffffff", padding:'5px', borderRadius:'10px'}} >
                        <center>
                            <p style={{fontSize:'0.8em'}}>{user.title}</p>
                            <img width='60px' src={user.imagesmall} />
                            {/* <p>cuid: {user.cuid}</p> */}
                            {/* <p>processtemplate: {user.processtemplate}</p> */}
                            {/* <p>description: {user.description}</p> */}
                        </center>
                    </div>
                </div>
            ))}
        </>
    );
}

