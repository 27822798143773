import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const REWARDS = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [gameid, setGameid] = useState({});
    const [func, setFunc] = useState({});


    useEffect(() => {
        api_app_reward_game()
        api_app_reward_status()
        api_app_reward_history()

    }, []); 

    const api_app_reward_status = () => {
        WEBCALL("app_reward_status", setLoading, setFunc, setError, uid, 
            {
                'func':func
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_reward_game = () => {
        WEBCALL("app_reward_game", setLoading, setGameid, setError, uid, 
            {
                'gameid':gameid
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_reward_history = () => {
        WEBCALL("app_reward_history", setLoading, setData, setError, uid, {}, 
            (data) => {}, 
            (error) => {}
        );
    };
    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
        <JMT_FIELD_HEADER title="Rewards"/>
        
            <pre>Rewards Satus |data: {JSON.stringify(func, null, "\t")}</pre>
            <pre>Reward Game |data: {JSON.stringify(gameid, null, "\t")}</pre>
            <pre>HISTORY |data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}

