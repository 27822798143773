import React, { useState, useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const HELP = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    // useEffect(() => {
    //     handleLoad(); 
    // }, []); 

    // const handleLoad = () => {
    //     WEBCALL("app_acc_status", setLoading, setData, setError, uid, {}, 
    //         (data) => {}, 
    //         (error) => {}
    //     );
    // };

    const help_data = [
        {
            title: "How to become an Agent",
            content: `Download and register your details, contact your closest Regional TSPC office form the list provided.
                    Go to the SETTINGS option in the side menu to update your identity and proof of residence documents.`,
        },
        {
            title: "Deposit into your TSPC App Wallet",
            content: `<p><b>IMPORTANT:</b> When making a deposit please use the TSPC Agent Number as reference.</p>
                    <p style="text-align:left">
                    <b><span id="clientnumber"></span></b>
                    </p>
                    <p>Please use the below bank account details when loading money: Minimum deposit of R300.</p>
                    <p>
                    <b>BANK:</b> FNB<br>
                    <b>ACCOUNT NAME:</b> TSPC APP DEPOSIT ACCOUNT<br>
                    <b>ACCOUNT NUMBER:</b> 6280 4453 942<br>
                    <b>BRANCH:</b> 250 655<br>
                    </p>
                    <p>During working hours please allow 1hour for deposits to reflect in your App balance.</p>
                    <p><b>Support email address:</b>appdeposit@tspc.co.za<br>
                    <b>Support contact number during working hours only:</b> (011) 466-1813<br></p>`,
        },
        {
            title: "RICA Agent Legal Requirements",
            content: `<p>As a RICA agent you are responsible for the SIM activations that you perform.</p>
                    <p>
                        <ul class="help-accordion-ul"
                            style="margin: 2%;">
                            <li>I agree to validate a mobile phone subscribers details against their proof of identification (South African ID book or passport) ensuring that the subscriber resembles the photograph within.</li>
                            <li>I agree to verify a subscribers address by inspecting a bank statement, municipal rates and taxes invoice, telephone or cellular phone account not older than 3 months, or any other utility bill or an account of a retailer not older than 3 months, or an existing lease, rental or credit sale agreement, insurance policy, a current TV licence or a new motor vehicle licence document.</li>
                            <li>I undertake to capture a mobile phone subscriber's RICA particulars as accurately as possible</li>
                            <li>I undertake to prevent fraudulent submission of false registration information which may result in a RICA registration failure and failed subscriber activation.</li>
                            <li>I agree to attend to the registration of a mobile phone subscriber in accordance with the requirements of the RICA act, and I understand that this requirement may change from time to time.</li>
                            <li>I agree to keep all information disclosed to me by a mobile phone subscriber, confidential and will not disclose any RICA information to 3rd parties.</li>
                            <li>I undertake to perform a RICA registration on all persons to the best of my ability.</li>
                            <li>I warrant that the information provided is true and accurate.</li>
                        </ul>
                    </p>`,
        },
        {
            title: "Agent Reports",
            content: `<b>Go to reports in the side menu and select REPORTS</b>`,
        },
        {
            title: "Support Contact",
            content: `<b>
                        <p>
                            <ul class="help-accordion-ul" style="margin: 2%;">
                                <li>Johannesburg: (011) 466-1813</li>
                                <li>Cape Town: (021) 702-4272</li>
                                <li>Durban: (031) 502-4574</li>
                                <li>East London: (043) 726-0180</li>
                            </ul>
                        </p>
                    </b>`,
        },
    ];

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
                <Accordion activeIndex={0} style={{borderRadius:'15px'}}>
                   {help_data.map(row => (
                        <AccordionTab header={row.title}>
                            <div dangerouslySetInnerHTML={{ __html: row.content }} />
                        </AccordionTab>
                    ))}
                </Accordion>
        </JMT_LOADING_PANEL>
    );
}