import React from 'react';

import { Button } from 'primereact/button';

export const JMT_HOME_BUTTON = ({ caption, icon, value, color, to_page, pageSetter }) => {

    return (
        <div className="col-3" onClick={() => pageSetter(to_page)}>
            <div style={{backgroundColor:color, height:'100px', padding:'10px', borderRadius:'15px', fontSize:'0.65em', color:'white'}}>
                <center>
                    <b>{caption}</b>
                    <br/>
                    <br/>
                    <i className={`pi ${icon}`} style={{'fontSize': '3em', 'color':'white'}}>
                    </i>
                    <br/>
                    <br/>
                    <b>{value}</b>
                </center>
            </div>
        </div>
    );
}

