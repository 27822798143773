import React, { useEffect } from 'react';
import { Avatar } from 'primereact/avatar';

export const JMT_PAGE_HEADER = ({ title, data, pageSetter, setUID }) => {

    // useEffect(() => {
    //     const interval = setInterval(() => {

    //     }, 30000);
    //     return () => clearInterval(interval);  
    // }, [data]); 

    return (

        // <header className="sticky-header">
        <header className="header">
        <div style={{ padding:'0px 10px 0px' }}>
            <br/>
            <div className="grid">
                <div className="col-3" style={{margin:'0px', padding:'2px'}}>
                    {
                        title === "HOME"
                        ?
                            <Avatar 
                                icon="pi pi-power-off" 
                                className="p-overlay-badge"                            
                                size="small" 
                                shape="circle" 
                                onClick={() => {
                                    pageSetter("LOGIN");
                                    setUID("");
                                }}

                            />
                        :
                            <Avatar 
                                icon="pi pi-home" 
                                className="p-overlay-badge"                            
                                size="small" 
                                shape="circle" 
                                onClick={() => pageSetter("HOME")}
                            />
                    }
                <br/>
                </div>
                <div className="col-6" style={{margin:'0px', padding:'2px'}}>
                    <center>
                        <h5 style={{color:'white', padding:'5px', margin:'0px', alignItems:'center', fontSize:'1em'}}>{title.replace(/_/g, " ")}</h5>
                    </center>
                </div>
                <div className="col-3" style={{ margin: '0px', padding:'2px', display: 'flex', justifyContent: 'flex-end' }}>
                    <img height="35px" src="/images/logo.png" alt="Logo" onClick={() => pageSetter("HELP")}/>
                </div>

            </div>
        </div>
        </header>
    );
}

