import React, { useState, useEffect } from 'react';

import { WEBCALL } from '../../components/webcall'; 
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { Avatar } from 'primereact/avatar';

import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { JMT_FIELD_LABEL } from '../../components/jmt_field_label'; 
import { JMT_FIELD_HEADER } from '../../components/jmt_field_header';
import { JMT_FIELD_DROPDOWN } from '../../components/jmt_field_dropdown';
import { JMT_FIELD_TEXTBOX } from '../../components/jmt_field_textbox';
import { JMT_FIELD_BUTTON } from '../../components/jmt_field_button';

import 'primeicons/primeicons.css';
 
export const PROFILE = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [parameter, setParameter] = useState({});
    
    const [firstName, setFirstName] = useState({});
    const [surname, setSurname] = useState({});
    const [title, setTitle] = useState({});
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [building, setBuilding] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [country, setCountry] = useState("");
    const [postcode, setPostcode] = useState("");
    const [id_type, setId_type] = useState({});
    const [id_passport, setId_passport] = useState("");

    useEffect(() => {
        api_app_userdetails(); 
    }, []); 
    
    const api_app_userdetails = () => {
        WEBCALL("app_userdetails", setLoading, setData, setError, uid, {}, 
            (data) => {
                setFirstName(data?.app_userdetailslist[0]?.firstname);
                setSurname(data?.app_userdetailslist[0]?.surname);
                setTitle(data?.app_userdetailslist[0]?.title);
                setEmail(data?.app_userdetailslist[0]?.email);
                setMobile(data?.app_userdetailslist[0]?.mobile);

                setAddress1(data?.app_userdetailslist[0]?.address1);
                setAddress2(data?.app_userdetailslist[0]?.address2);
                setBuilding(data?.app_userdetailslist[0]?.building);
                setCity(data?.app_userdetailslist[0]?.city);
                setProvince(data?.app_userdetailslist[0]?.province);
                setCountry(data?.app_userdetailslist[0]?.country);
                setPostcode(data?.app_userdetailslist[0]?.postcode);

                setId_type(data?.app_userdetailslist[0]?.id_type);
                setId_passport(data?.app_userdetailslist[0]?.id_passport);
            }, 
            (error) => {}
        );
    };

    const api_app_user_update_details = () => {
        WEBCALL("app_user_update_details", setLoading, setParameter, setError, uid, 
            {
                "title": title.title,
			    "firstname": firstName,
			    "surname": surname,
			    "email": email,
			    "mobile": mobile,
			    "id_passport": id_passport,
			    "id_type": id_type.id_type,
			    "address1": address1,
			    "address2": address2,
			    "building": building,
			    "city": city,
			    "postcode": postcode,
			    "province": province,
			    "country": country,
                "uid":uid
			    // "avbalance": "0.00",
			    // "incentive": "0.00",
			    // "uid": "EBE0D703-6CCE-4367-BCE7-0671A88D1FBA"
            }, 
            (data) => {
            }, 
            (error) => {}
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <br/>
                <JMT_FIELD_HEADER title="Your Profile Information"/>
                <JMT_FIELD_TEXTBOX id="firstname" caption="First Name" value={firstName} setter={setFirstName}/>
                <JMT_FIELD_TEXTBOX id="surname" caption="Surname" value={surname} setter={setSurname}/>
            <br/>
                <JMT_FIELD_HEADER title="Account Information"/>
                {/* <JMT_FIELD_LABEL id="scode" caption="Client Number:" value={data?.app_userdetailslist[0]?.scode}/>
                <JMT_FIELD_LABEL id="" caption="Account Number:" value={data?.app_userdetailslist[0]?.scode}/>
                <JMT_FIELD_LABEL id="" caption="Client Code:" value={data?.app_userdetailslist[0]?.scode}/> */}
            <br/>
                <JMT_FIELD_HEADER title="Personal Information"/>
                <JMT_FIELD_DROPDOWN caption="Title" id="title" value={title} list={[
                    {"title":"MR", "value":"MR"},
                    {"title":"MRS", "value":"MRS"},
                    ]} setter={setTitle}/>
                <JMT_FIELD_TEXTBOX id="email" caption="Email Address" value={email} setter={setEmail}/>
                <JMT_FIELD_TEXTBOX id="mobile" caption="Mobile Number" value={mobile} setter={setMobile}/>
            <br/>
                <JMT_FIELD_HEADER title="Billing Information"/>
                <JMT_FIELD_TEXTBOX id="address1" caption="Address line 1" value={address1} setter={setAddress1}/>
                <JMT_FIELD_TEXTBOX id="address2" caption="Address line 2" value={address2} setter={setAddress2}/>
                <JMT_FIELD_TEXTBOX id="building" caption="Building Details" value={building} setter={setBuilding}/>
                <JMT_FIELD_TEXTBOX id="city" caption="City" value={city} setter={setCity}/>
                <JMT_FIELD_TEXTBOX id="province" caption="Province" value={province} setter={setProvince}/>
                <JMT_FIELD_TEXTBOX id="country" caption="County Details" value={country} setter={setCountry}/>
                <JMT_FIELD_TEXTBOX id="postcode" caption="Postal Code Detail" value={postcode} setter={setPostcode}/>
            <br/>
                <JMT_FIELD_HEADER title="Identification"/>
                <JMT_FIELD_DROPDOWN id="id_type" caption="Identification Type" value={id_type} list={[
                    {"id_type":"Passport", "value":"Passport"}, 
                    {"id_type":"SAID", "value":"SAID"}, 
                ]} setter={setId_type}/>
                <JMT_FIELD_TEXTBOX id="id_passport" caption="ID/Passport Number" value={id_passport} setter={setId_passport}/>
            <br/>
                <JMT_FIELD_BUTTON caption="Update Details" onclick={api_app_user_update_details}/>


            <pre>Title: {JSON.stringify(title, null, "\t")}</pre>
            <pre>Written_data: {JSON.stringify(parameter, null, "\t")}</pre>
            <pre>profiledata: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}