import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const SURVEY = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [SURVEY_NAME, setDatasetSurveyName] = useState({});
    const [jsonData, setJsonData] = useState({});


    useEffect(() => {
        api_app_survey_access();
        //api_app_survey_data();
    }, []); 

    const api_app_survey_access = () => {
        WEBCALL("app_survey_access", setLoading, setData, setError, uid, 
            {
                "surveyname": SURVEY_NAME
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_survey_data = () => {
        WEBCALL("app_survey_data", setLoading, setJsonData, setError, uid, 
            {
                //"surveyname": SURVEY_NAME, 
                "jsondata": jsonData 
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <JMT_FIELD_HEADER title="Survey"/>

            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}