import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const NEWS = ({ pageSetter, uid, profile_data }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});
    
    const [displayBasic, setDisplayBasic] = useState(false);
    const [article, setArticle] = useState({});

    useEffect(() => {
        api_app_news_get()
    }, []); 


    const api_app_news_get = () => {
        WEBCALL("app_news_get", setLoading, setData, setError, uid, {},
            (data) => {}, 
            (error) => {}
        );
    };

    const displayArticle = (rowData) => {
        setDisplayBasic(true);
        var new_body = rowData?.body;
        new_body = new_body.split('#.').join("\n");
        new_body = new_body.split('#q').join('"');
        var aticleData = {
            new_body, 
            ...rowData
        } 
        setArticle(aticleData);
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <Dialog 
                header={article.title} 
                visible={displayBasic} 
                style={{ width: '90vw' }} 
                onHide={() => setDisplayBasic(false)}
            >
                    {article.imagelarge !== ""?<img width="100%" src={article.imagelarge}/>:<></>}
                    <center>
                        <div dangerouslySetInnerHTML={{ __html: article?.new_body }} />
                    </center>
                    {/* <pre>data: {JSON.stringify(article, null, "\t")}</pre> */}
            </Dialog>
            <div style={{ width:'100%', borderRadius: '15px', overflow: 'hidden' }}>
                <DataTable 
                    value={data?.app_news_getlist} 
                    showHeader={false} 
                    style={{ borderRadius: '15px' }}
                    onRowClick={(e) => {displayArticle(e.data);}} 
                >
                    <Column field="icon" body={(rowData) => {return <img width="50px" src={rowData.imagesmall} />}}/>
                    <Column field="title" />
                </DataTable>
            </div>
            {/* <pre>data: {JSON.stringify(data, null, "\t")}</pre> */}
        </JMT_LOADING_PANEL>
    );
}