import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const CELLC_OFFERS = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [ETOPUP_TXUID, set_ETOPUP_TXUID] = useState({});

    const [product_id, set_product_id] = useState({});

    useEffect(() => {
        api_app_store_etopup_ext_product();
        //api_app_store_txpoll(); 
    }, []); 

    const api_app_store_txpoll = () => {
        WEBCALL("app_store_txpoll", setLoading, setData, setError, uid, 
                {
                    "txuid": ETOPUP_TXUID
                }, 
            (data) => {}, 
            (error) => {}
        );
    };
    const api_app_store_etopup_ext_product = () => {
        WEBCALL("app_store_etopup_ext_product", setLoading, setData, setError, uid, 
            {
                // "reason":  'Transfer',
                // "mobile":  mobile,
                // "wallet": ZAR,
                // "wallet": ZAR
                "extproductid":product_id,
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <JMT_FIELD_HEADER title="Cell-C Special Offers"/>

            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}