import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const DSTV = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [DSTV_TXUID, set_DSTV_TXUID] = useState({});

    useEffect(() => {
        api_app_store_txpoll();
    }, []);

    const api_app_store_txpoll = () => {
        WEBCALL("app_store_txpoll", setLoading, set_DSTV_TXUID, setError, uid, 
            {
                "txuid": DSTV_TXUID 
            }, 
            (data) => {}, 
            (error) => {}
        );
    };
    
    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <JMT_FIELD_HEADER title="DSTV"/>


            <pre>data: {JSON.stringify(DSTV_TXUID, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}