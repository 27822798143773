import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const RICA = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});


    const [earncomnow, setEarnComNow] = useState({});
    const [sim, setSim] = useState({});

    const [status, setStatus] = useState("PENDING");

    // const menu =[


    // ]

    useEffect(() => {
        api_app_rica_list();
        // api_app_sim_box();
        // api_app_simpack_status();
        // api_app_rica_reload();
    }, []); 

    
        const api_app_rica_list = () => {
            WEBCALL("app_rica_list", setLoading, setData, setError, uid, 
                {
                    "lastid": 0,
                    "status": status,
                }, 
                (data) => {}, 
                (error) => {}
            );
        };

    const api_app_sim_box = () => {
        WEBCALL("app_sim_box", setLoading, setData, setError, uid, 
            {
                "earnnow": earncomnow
            },     
            (data) => {}, 
            (error) => {}
        );    
    };    

    const api_app_simpack_status = () => {
        WEBCALL("app_simpack_status", setLoading, setData, setError, uid, 
            {
                "simref": sim, 
            },     
            (data) => {}, 
            (error) => {}
        );    
    };        

    const api_app_rica_reload = () => {
        WEBCALL("app_rica_reload", setLoading, setData, setError, uid, {}, 
            (data) => {}, 
            (error) => {}
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <JMT_FIELD_HEADER title="Rica"/>


            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}