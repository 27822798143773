import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const TRANSACTIONS = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [ lastid, set_lastid] = useState({});

    useEffect(() => {
        api_app_wallet_statement(); 
    }, []); 

    const api_app_wallet_statement = () => {
        WEBCALL("app_wallet_statement", setLoading, setData, setError, uid, 
            {
                // "lastid": lastid
                "lastid": lastid
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <div style={{ width:'100%', borderRadius: '15px', overflow: 'hidden' }}>
                <DataTable 
                    value={data?.app_wallet_statementlist} 
                    showHeader={false} 
                    style={{ borderRadius: '15px' }}
                >
                    {/* <Column field="icon" body={(rowData) => {return <img width="50px" src={rowData.icon} />}}/> */}
                    <Column body={(rowdata)=>{return <>{rowdata.date}<br/>{rowdata.reference}</>}}/>
                    {/* <Column field="date" /> */}
                    {/* <Column field="reference" /> */}
                    <Column body={(rowdata)=>{return <>Amt<br/>Bal</>}}/>
                    <Column body={(rowdata)=>{return <>{rowdata.amount}<br/>{rowdata.balance}</>}}/>
                    {/* <Column field="amount" /> */}
                    {/* <Column field="balance" /> */}
                </DataTable>
            </div>
            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}