import React, { useState, useEffect } from 'react';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';

import { JMT_CAROUSEL } from '../../components/jmt_carousel'; 
import { WEBCALL } from '../../components/webcall'; 

import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { JMT_HOME_BUTTON } from '../../components/jmt_home_button'; 
import { JMT_FIELD_LABEL } from '../../components/jmt_field_label'; 
import { JMT_TSPC_CATEGORY } from '../../components/jmt_tspc_category';

export const HOME = ({ pageSetter, uid, profile_data }) => {

    const [loading_acc_status, setLoading_acc_status] = useState(true);
    const [error_acc_status, setError_acc_status] = useState("");
    const [data_acc_status, setData_acc_status] = useState({});

    const [loading_storepage, setLoading_storepage] = useState(true);
    const [error_storepage, setError_storepage] = useState("");
    const [data_storepage, setData_storepage] = useState({});

    useEffect(() => {
        api_app_acc_status(); 
         api_app_storepage(); 
    }, []); 

    const api_app_acc_status= () => {
        WEBCALL("app_acc_status", setLoading_acc_status, setData_acc_status, setError_acc_status, uid, {}, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_storepage = () => {
        WEBCALL("app_storepage", setLoading_storepage, setData_storepage, setError_storepage, uid, 
            {
                "qty": 100,
                "catid": "HOME", 
            }, 
            (data) => {}, 
            (error) => {}
        );
    };
    return (
        <>
            <div style={{width:'100%', height:'80px', backgroundColor:'red', fontSize:'3em'}}><center>WIP</center></div>
            <JMT_LOADING_PANEL loading={loading_storepage} data={data_storepage} error={error_storepage}>
                <JMT_CAROUSEL data={data_storepage?.app_storepagelist}/>
            </JMT_LOADING_PANEL>
            <JMT_LOADING_PANEL loading={loading_acc_status} data={data_acc_status} error={error_acc_status}>
                <div className="grid">
                    <div className="col-3">
                        <center>
                        <Avatar
                            shape="circle"
                            label={(data_acc_status?.firstname?.toUpperCase().slice(0,1))+(data_acc_status?.surname?.toUpperCase().slice(0,1))}
                            size="large" // You can use 'small', 'medium', or 'large' depending on the desired size
                            style={{ backgroundColor: '#007bff', color: '#fff', fontSize: '1.5rem' }} // Customize the style
                            onClick={() => pageSetter("PROFILE")}
                        />
                        </center>
                    </div>   
                    <div className="col-6">
                        <center>
                        <span style={{fontSize:'1.4em'}}>{data_acc_status.firstname} {data_acc_status?.surname}</span><br/>
                        <span style={{fontSize:'1em'}}>Code: {data_acc_status.scode}</span><br/>
                        <span style={{fontSize:'1em'}}>Account: {data_acc_status.accountnumber}</span><br/>
                        </center>
                    </div>   
                    <div className="col-3">
                        <center>
                        <Avatar 
                            icon="pi pi-inbox" 
                            className="p-overlay-badge"                            
                            size="large" 
                            shape="circle" 
                            onClick={() => pageSetter("MESSAGES")}
                        >
                        {
                            data_acc_status.msgcount !== "0"
                            ?
                            <Badge value={data_acc_status.msgcount} severity="danger"/>
                            :
                            <></>
                        }
                        </Avatar>
                        </center>
                    </div>   
                </div>        
                <br/>
                <div className="grid">
                    <JMT_HOME_BUTTON caption="Wallet" icon="pi-wallet" value={`R ${data_acc_status.avbalance}`} color="#047a37" to_page="EWALLETS" pageSetter={pageSetter}/>
                    <JMT_HOME_BUTTON caption="Rewards" icon="pi-gift" value={`R ${data_acc_status.incentive}`} color="#0387c9" to_page="REWARDS" pageSetter={pageSetter}/>
                    <JMT_HOME_BUTTON caption="Transactions" icon="pi-money-bill" value="" color="#FFCB05" to_page="TRANSACTIONS" pageSetter={pageSetter}/>
                    <JMT_HOME_BUTTON caption="Transfers" icon="pi-arrow-right-arrow-left" value="" color="#D4171F" to_page="TRANSFERS" pageSetter={pageSetter}/>
                </div>
                <br/>
            </JMT_LOADING_PANEL>
            <JMT_LOADING_PANEL loading={loading_storepage} data={data_storepage} error={error_storepage}>
                <div className="grid">
                    <JMT_TSPC_CATEGORY pageSetter={pageSetter} data={data_storepage?.app_storepagelist}/>                    
                </div>
            </JMT_LOADING_PANEL>
            {/* <pre>data: {JSON.stringify({data_acc_status}, null, "\t")}</pre> */}
            {/* <pre>data: {JSON.stringify({data_storepage}, null, "\t")}</pre> */}
        </>
    );
}