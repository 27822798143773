import React from 'react';
import { Dropdown } from 'primereact/dropdown';

export const JMT_FIELD_DROPDOWN = ({ caption, id, value, list, setter }) => {

    return (
        <div style={{padding:'2px'}} className="col-12 md:col-12">
            <div className="p-inputgroup border-bottom-0">
                {/* <span style={{width:'300px', padding:'2px'}} >{caption}</span> */}
                <Dropdown 
                    style={{width:'100%', padding:'0px', borderRadius:'5px'}} 
                    id={id} 
                    value={value} 
                    onChange={(e) => {setter(e.value);}} 
                    options={list} 
                    optionLabel={id} 
                    placeholder={caption} 
                />
            </div>
        </div>
    );
}

