import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';

import { JMT_FIELD_HEADER } from "../../components/jmt_field_header";
import { JMT_FIELD_TEXTBOX } from "../../components/jmt_field_textbox";
import { JMT_LOADING_PANEL } from "../../components/jmt_loading_panel";
import { JMT_TABS } from "../../components/jmt_tabs";
import { WEBCALL } from "../../components/webcall";
import { JMT_FIELD_DROPDOWN } from "../../components/jmt_field_dropdown";
import { JMT_FIELD_BUTTON } from "../../components/jmt_field_button";
import { JMT_INPUT_SWITCH } from "../../components/jmt_input_switch";
import { JMT_FIELD_TEXTAREA } from "../../components/jmt_field_textarea";

export const SETTINGS = ({ pageSetter, uid, profile_data }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    // UPLOAD DOCUMENTS
    const [docType, setDocType] = useState({});

    // PASSWORD RESET
    const [mobile, setMobileNumber] = useState("");
    const [surname, setSurname] = useState("");
    // const [dob, setDateOfBirth] = useState({});
    const [idtype, setTypeID] = useState("");
    const [id_passport, setTypePassport] = useState("");
    const [old_pw, setOldPassword] = useState("");
    const [new_pw, setNewPassword] = useState("");
    const [conf_pw, setConfirmNewPassword] = useState("");

    // PUSH NOTIFICATIONS

    // ADDITIONAL SETTINGS
    const [deviceInformation, setDeviceInformation] = useState("");
    const [currentPrinter, setCurrentPrinter] = useState("");
    // const [selectPrinter, setSelectPrinter] = useState({});
    const [printerHeader, setPrinterHeader] = useState("");
    const [printerFooter, setPrinterFooter] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [accuracy, setAccuracy] = useState("");
    const [altitude, setAltitude] = useState("");



    const api_app_file_upload = (json) => {
        WEBCALL("app_uploaddocs", setLoading, setData, setError, uid,
            {
                // filename: filename
            },
            (data) => {
                // api_app_file_upload_complete(json.filename, $("#profile_doc_type").val(), json.url);
            },
            (error) => { }
        );
    };

    const api_app_file_upload_complete = (filename, doctype, url) => {
        WEBCALL("app_file_upload", setLoading, setData, setError, uid,
            {
                // filename: filename,
                // doctype: doctype.doctype,
                // url: url,
                // update: "0",
                // upgrade: "0"
            },
            (data) => {
                // api_app_display_docs();
            },
            (error) => { }
        );
    };

    const api_app_display_docs = (json, docs) => {
        WEBCALL("app_user_docs", setLoading, setData, setError, uid,
            {
                // Empty 
            },
            (data) => {
                // fn_display_docs(json.app_user_docslist, docs);
            },
            (error) => { }
        );
    };

    const api_app_change_password = () => {
        WEBCALL("app_change_password", setLoading, setData, setError, uid,
            {
                // oldpassword: old_pw,
                // newpassword: new_pw,
            },
            (data) => {
                // PW_Updated();
            },
            (error) => { }
        );
    };

    const api_app_pwd_reset = () => {
        WEBCALL("app_pwd_reset", setLoading, setData, setError, uid,
            {
                // "mobile": mobile,
                // "surname": surname,
                // "dob": dob,
                // "id": id_passport,
            },
            (data) => { },
            (error) => { }
        );
    };

    const api_fetch_push_notifications = () => {
        WEBCALL("push_message_notify", "push_rica_notify", setLoading, setData, setError, uid,
            {

            },
            (data) => { 
                // setToggleState($("#push_message_notify"), data.pushEnabled);
                // setToggleState($("#push_rica_notify"), data.ricaEnabled);
            },
            (error) => { }
        );
    };

    const api_update_push_notifications = () => {
        WEBCALL("application/json", setLoading, setData, setError, uid,
            {
                // type: type, 
                // enabled: isEnabled
            },
            (data) => { },
            (error) => { }
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <Accordion activeIndex={0} style={{ borderRadius: '15px' }}>
                <AccordionTab header="Upload Your Documents">
                    <p>
                        You are currently registered as a<b>STANDARD</b>
                        user.
                        <br />
                        To upgrade to a<b>TSPC RICA Agent</b>, please submit a clear photo of your
                        <b>ID card / document</b>
                        along with a<b>proof of address.</b>
                    </p>
                    <p>
                        Select the document type that you are taking a picture of and then take a picture of the document with the
                        <b>Open Camera</b>
                        button.
                    </p>
                    <JMT_FIELD_DROPDOWN caption="Select Document Type" id="profile_doc_type" value={docType} setter={setDocType} list={[
                        { "profile_doc_type": "Proof of Identification", "value": "ID" },
                        { "profile_doc_type": "Proof of Address", "value": "ADDR" },
                    ]}
                    />
                    <i icon="fa-solid fa-paperclip"></i>
                    <h4>Capture Document Image</h4>
                    {/* <JMT_FIELD_BUTTON caption="Gallery" onclick={getImage} /> */}
                    {/* <JMT_FIELD_BUTTON caption="Open Camera" onclick={getPhoto} /> */}
                    <i icon="fa-solid fa-id-card"></i>
                    <h4>Display Your Documents</h4>
                    {/* <JMT_FIELD_BUTTON caption="Display Documents" onclick={displayDocs} /> */}
                    {/* <show_docs; Function to Display Documents id="docs_view" />  */}
                    <p>You will receive a notification regarding your application status within approx. 2 business days.</p>
                </AccordionTab>

                <AccordionTab header="Password Reset">
                    <p>To reset your account password, enter your mobile number, surname, date of birth, or your ID / Passport number.</p>
                    <p>This information must match your registered information. Upon completion, a new password will be sent to you via SMS.</p>
                    <p>If this process is not completed correctly, your account will be locked, and you will need to contact support.</p>
                    {/* <JMT_FIELD_BUTTON caption="Continue" onclick={reset_form} /> */}
                    <h4>Enter Your Details</h4>
                    <JMT_FIELD_TEXTBOX id="mobile" caption="Mobile Number" value={mobile} setter={setMobileNumber} />
                    <JMT_FIELD_TEXTBOX id="surname" caption="Surname" value={surname} setter={setSurname} />
                    {/* <JMT_FIELD_DROPDOWN caption="Select Date of Birth..." id="dob" value={dob} setter={setDateOfBirth} list={[
                        { "dob": "", "value": "dob" }
                    ]} /> */}
                    {/* I think list for dob will use API value for correct content, as previous was used to first call a Mobiscroll component before "date" content value... */}
                    <h4>OR</h4>
                    <JMT_FIELD_TEXTBOX id="id_passport" caption="Last 4 Digits of ID or Passport" value={idtype || id_passport} setter={setTypeID || setTypePassport} />
                    {/* <JMT_FIELD_BUTTON caption="Reset My Password" onclick={resetPassword} /> */}
                    <h4>Change Password</h4>
                    <JMT_FIELD_TEXTBOX id="settings_old_password" caption="Old Password" value={old_pw} setter={setOldPassword} />
                    <JMT_FIELD_TEXTBOX id="settings_new_password" caption="New Password" value={new_pw} setter={setNewPassword} />
                    <JMT_FIELD_TEXTBOX id="settings_confirm_new_password" caption="Confirm New Password" value={conf_pw} setter={setConfirmNewPassword} />
                    {/* <JMT_FIELD_BUTTON caption="Update My Password" onclick={updatePassword} /> */}
                    <label>Password Strength: </label>
                </AccordionTab>

                <AccordionTab header="Push Notifications">
                    <h2>Manage Push Notifications</h2>
                    {/* <JMT_INPUT_SWITCH id="push_message_notify", caption="Received Message Notifications" /> Component to Create */}
                    {/* <JMT_INPUT_SWITCH id="push_rica_notify", caption="RICA Message Notifications" /> */}
                </AccordionTab>

                <AccordionTab header="Additional Settings">
                    <h3>Bluetooth Printer</h3>
                    {/* <JMT_INPUT_SWITCH id="printer_toggle", caption="Enable Bluetooth Printing" /> Component to Create */}
                    {/* settings_current_printer is readonly, but uses same textbox for consistency */}
                    <JMT_FIELD_TEXTBOX id="settings_current_printer" caption="Current Printer" value={currentPrinter} setter={setCurrentPrinter} />
                    {/* <JMT_FIELD_DROPDOWN caption="Select Printer" id="settings_select_printer" value={selectPrinter} setter={setSelectPrinter} list={[
                        {"settings_select_printer": "", value: "selectPrinter"},
                        ]} /> */}
                    {/* <JMT_FIELD_BUTTON caption="Set Default Printer" onclick={defaultPrinter} /> */}
                    <JMT_FIELD_TEXTAREA caption="Print Header" id="printer_header" value={printerHeader} setter={setPrinterHeader} />
                    <JMT_FIELD_TEXTAREA caption="Print Footer" id="printer_footer" value={printerFooter} setter={setPrinterFooter} />
                    {/* <JMT_INPUT_SWITCH id="printer_barcode", caption="Print Voucher Barcode" /> */}
                    {/* <JMT_FIELD_BUTTON caption="Save" onclick={saveHeaderFooter} /> */}
                    <h3>Printer Test</h3>
                    {/* <JMT_FIELD_BUTTON caption="Test Print" onclick={testPrint} /> */}
                    {/* <JMT_FIELD_BUTTON caption="Feed" onclick={() => HP_form_feed(5)} /> */}
                    <h3>Device Information</h3>
                    {/* Device info is actually suppose to populate a table of info with its value / label parameters */}
                    <JMT_FIELD_TEXTBOX id="settings_device_info" caption="Display Device Information" value={deviceInformation} setter={setDeviceInformation} />
                    {/* I doubt the below is applicable to have this info display on screen,
                    but instead get geolocation via permission for the use of the App */}
                    <h3>Geolocation</h3>
                    <JMT_FIELD_TEXTBOX id="latitude" caption="Latitude" value={latitude} setter={setLatitude} />
                    <JMT_FIELD_TEXTBOX id="longitude" caption="Longitude" value={longitude} setter={setLongitude} />
                    <JMT_FIELD_TEXTBOX id="accuracy" caption="Accuracy" value={accuracy} setter={setAccuracy} />
                    <JMT_FIELD_TEXTBOX id="altitude" caption="Altitude" value={altitude} setter={setAltitude} />
                    <h3>Unable to Fetch Data</h3>
                    <p>There was an issue retrieving your device or location data. Please check your permissions and try again.</p>
                    {/* <JMT_FIELD_BUTTON caption='Retry' onclick={retryFetchData} /> */}
                </AccordionTab>
            </Accordion>

            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
};
