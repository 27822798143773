import React from 'react';
import { InputText } from 'primereact/inputtext';

export const JMT_FIELD_TEXTBOX = ({ id, caption, value, setter }) => {

    return (
        <div style={{padding:'2px'}} className="col-12 md:col-12">
            <div className="p-inputgroup border-bottom-0">
                {/* <span style={{width:'300px', padding:'2px'}} >{caption}</span> */}
                <InputText 
                    style={{width:'100%', padding:'10px', borderRadius:'5px'}} 
                    id={id} 
                    value={value} 
                    placeholder={caption} 
                    autoComplete="off"
                    onChange={(e) => setter(e.target.value)}
                />
            </div>
        </div>
    );
}

