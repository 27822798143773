import React, { useState } from 'react';

import { JMT_PAGE_HEADER } from './components/jmt_page_header';
import { JMT_PAGE_FOOTER } from './components/jmt_page_footer';

import { CART } from './pages/account/cart';
import { CELLC_OFFERS } from './pages/account/cellc_cvm_offers';
import { EWALLETS } from './pages/account/ewallets';
import { GAMES } from './pages/account/games';
import { POCKET } from './pages/account/pocket';
import { SIM_STOCK_CHECK } from './pages/account/sim_stock_check';
import { TOPUP } from './pages/account/topup';
import { TRANSACTIONS } from './pages/account/transactions';
import { TRANSFERS } from './pages/account/transfers';
import { ORDERS } from './pages/account/orders';
import { LOGIN } from './pages/_base/login';
import { REGISTER } from './pages/_base/register';
import { HELP } from './pages/profile/help';
import { MESSAGES } from './pages/profile/messages';
import { SETTINGS } from './pages/profile/settings';
import { DSTV } from './pages/store/dstv';
import { ETOPUP } from './pages/store/e_topup';
import { EARN_COMM_NOW } from './pages/store/earn_comm_now';
import { ELECTRICITY } from './pages/store/electricity';
import { HOLLYWOOD } from './pages/store/hollywood';
import { LOTTO } from './pages/store/lotto';
import { NEWS } from './pages/store/news';
import { STORE_PAGE_SA_COUPON } from './pages/store/store_page_sa_coupon';
import { STORE_PAGE } from './pages/store/store_page';
import { SURVEY } from './pages/store/survey';
import { REWARDS } from './pages/store/rewards';
import { TRAINING } from './pages/store/training';
import { VIA_MEDIA_REWARDS_PRODUCTS } from './pages/store/via_media_rewards_products';
import { VIA_MEDIA_REWARDS } from './pages/store/via_media_rewards';
import { VOUCHER_SA_COUPON_BUY } from './pages/store/voucher_sa_coupon_buy';
import { VOUCHER } from './pages/store/voucher';
import { WEB_TICKET } from './pages/store/web_ticket';
import { REPORTS } from './pages/bottom/reports';
import { ACCOUNT } from './pages/bottom/account';
import { PROFILE } from './pages/bottom/profile';
import { RICA } from './pages/bottom/rica';
import { HOME } from './pages/bottom/home';

import './App.css';

const App = () => {
    const [CurrentView, setCurrentView] = useState("HOME");    
    const [data, setData] = useState({});
    const [uid, setUID] = useState("");

    const setCurView = (page, cuid="") => {
        console.log(cuid);
        setCurrentView(page);
        setData(cuid);
    }

    const renderPage = () => {
        //accounts
        if(CurrentView === "CART"){                         return <CART                         uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "CELLC_OFFERS"){                 return <CELLC_OFFERS                 uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "EWALLETS"){                     return <EWALLETS                     uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "GAMES"){                        return <GAMES                        uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "POCKET"){                       return <POCKET                       uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "SIM_STOCK_CHECK"){              return <SIM_STOCK_CHECK              uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "TOPUP"){                        return <TOPUP                        uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "TRANSACTIONS"){                 return <TRANSACTIONS                 uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "TRANSFERS"){                    return <TRANSFERS                    uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "ORDERS"){                       return <ORDERS                       uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "REGISTER"){                        return <REGISTER                        uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "ACCOUNT"){                       return <ACCOUNT                       uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "RICA"){                          return <RICA                          uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "PROFILE"){                       return <PROFILE                       uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "REPORTS"){                       return <REPORTS                       uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "HOME"){                          return <HOME                          uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "HELP"){                         return <HELP                         uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "MESSAGES"){                     return <MESSAGES                     uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "SETTINGS"){                     return <SETTINGS                     uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "DSTV"){                       return <DSTV                       uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "ETOPUP"){                     return <ETOPUP                     uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "REWARDS"){                    return <REWARDS                    uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "EARN_COMM_NOW"){              return <EARN_COMM_NOW              uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "ELECTRICITY"){                return <ELECTRICITY                uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "HOLLYWOOD"){                  return <HOLLYWOOD                  uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "LOTTO"){                      return <LOTTO                      uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "NEWS"){                       return <NEWS                       uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "STORE_PAGE_SA_COUPON"){       return <STORE_PAGE_SA_COUPON       uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "STORE_PAGE"){                 return <STORE_PAGE                 uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "SURVEY"){                     return <SURVEY                     uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "TRAINING"){                   return <TRAINING                   uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "VIA_MEDIA_REWARDS_PRODUCTS"){ return <VIA_MEDIA_REWARDS_PRODUCTS uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "VIA_MEDIA_REWARDS"){          return <VIA_MEDIA_REWARDS          uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "VOUCHER_SA_COUPON_BUY"){      return <VOUCHER_SA_COUPON_BUY      uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "VOUCHER"){                    return <VOUCHER                    uid={uid} profile_data={data} pageSetter={setCurView}/>}
        if(CurrentView === "WEB_TICKET"){                 return <WEB_TICKET                 uid={uid} profile_data={data} pageSetter={setCurView}/>}
    }

    return (
        <>
        {
            uid == ""
            ?
            <>
                <LOGIN  setUID={setUID} profile_data={data} pageSetter={setCurView}/>
            </>
            :
            <>
            <div className="app-container">
                <JMT_PAGE_HEADER setUID={setUID} title={CurrentView} data={data} pageSetter={setCurView} />
                <div className="body">
                    <div className="grid" style={{ padding:'0px'}}>
                        <div className="col-12 lg:col-4" style={{ padding:'0px'}}>
                        </div>
                        <div className="col-12 lg:col-4">
                            <div style={{ padding:'0px' }}>
                                {renderPage()}
                            </div>
                        </div>
                        <div className="col-12 lg:col-4" style={{ padding:'0px'}}>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                {/* <pre>uid: {JSON.stringify(uid, null, "\t")}</pre> */}
                {/* <pre>CurrentView: {JSON.stringify(CurrentView, null, "\t")}</pre> */}
                {/* <pre>data: {JSON.stringify(data, null, "\t")}</pre> */}
                {/* <pre>session_data: {JSON.stringify(data?.session_data, null, "\t")}</pre> */}
                {/* <pre>expired: {JSON.stringify(expired, null, "\t")}</pre> */}
                {/* Footer */}
                <JMT_PAGE_FOOTER title={CurrentView} uid={uid} screen={CurrentView} pageSetter={setCurView}/>
            </div>
            </>
        }
      </>
    );
};
export default App;
