import React from 'react';

import { JMT_ERROR } from './jmt_error'; 
import { JMT_PROGRESS_BAR } from './jmt_progress_bar'; 

export const JMT_LOADING_PANEL = ({ loading, error, data, children }) => {

    return (
        <>
            {
                loading !== true
                ?
                    <>
                        {
                            error !== ""
                            ?
                            <JMT_ERROR loading={loading} error={error}/>
                            :
                            <div>
                                {children}
                            </div>
                        }
                    </>
                :
                <JMT_PROGRESS_BAR loading={loading}/>
            }
            {/* <pre>data: {JSON.stringify(data, null, "\t")}</pre> */}
            {/* <pre>loading: {JSON.stringify(loading, null, "\t")}</pre> */}
            {/* <pre>error: {JSON.stringify(error, null, "\t")}</pre> */}
        </>        
    );
}

