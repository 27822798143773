import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const SIM_STOCK_CHECK = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});
    
    const [box, set_box] = useState({});
    const [sim, set_sim] = useState({});
    const [CHECK_SIM_MULTI_SIM, set_CHECK_SIM_MULTI_SIM] = useState({});
    const [status, set_status] = useState({});


    useEffect(() => {
        handleLoad(); 
        // api_app_sim_box();
        api_app_simpack_status();
        // api_app_simpack_multistatus();
        // api_app_check_sim_list();
        // api_app_check_sim_reload();  Breaks on this
    }, []); 

    const handleLoad = () => {
        WEBCALL("app_acc_status", setLoading, setData, setError, uid, {}, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_rica_submit = () => {
        WEBCALL("app_rica_submit", setLoading, setData, setError, uid, {}, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_sim_box = () => {
        WEBCALL("app_sim_box", setLoading, setData, setError, uid, 
            {
                "box": box
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_simpack_status = () => {
        WEBCALL("app_simpack_status", setLoading, setData, setError, uid, 
            {
                "simref": sim
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    
    const api_app_simpack_multistatus = () => {
        WEBCALL("app_simpack_multistatus", setLoading, setData, setError, uid, 
            {
                "simref":  CHECK_SIM_MULTI_SIM
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_check_sim_list = () => {
        WEBCALL("app_check_sim_list", setLoading, setData, setError, uid, 
            {
                "status": status
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

 
    const api_app_check_sim_reload = () => {
       WEBCALL("app_check_sim_reload", setLoading, setData, setError, uid, 
        {
            "uid": uid
        }, 
        (data) => {}, 
        (error) => {}
       );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <JMT_FIELD_HEADER title="Sim Stock Check"/>



            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}