import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const ETOPUP = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [puid, set_puid] = useState({});
    const [ETOPUP_TXUID, set_ETOPUP_TXUID] = useState({});

    useEffect(() => {
        api_app_store_epbuysetup(); 
    }, []); 
    
    const api_app_store_epbuysetup = () => {
        WEBCALL("app_store_epbuysetup", setLoading, setData, setError, uid, 
            {
                "puid": puid
            }, 
            (data) => {}, 
            (error) => {}
        );
    };
    const api_app_store_txpoll = () => {
        WEBCALL("app_store_txpoll", setLoading, set_ETOPUP_TXUID, setError, uid, 
            {
                "txuid": ETOPUP_TXUID
            }, 
            (data) => {}, 
            (error) => {}
        );
    };
    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <JMT_FIELD_HEADER title="eTop-Up"/>


            <pre>data: {JSON.stringify(setData, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}