import React from 'react';
import { ProgressBar } from 'primereact/progressbar';

export const JMT_PROGRESS_BAR = ({ loading }) => {

    return (
        <>
            {
                loading === true
                ?                
                <ProgressBar mode="indeterminate" style={{ margin:'5px', height: '6px' }}></ProgressBar>
                :
                <></>
            }
        </>
    );
}

