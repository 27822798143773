import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const VIA_MEDIA_REWARDS_PRODUCTS = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [reportname, set_reportname] = useState({});
    const [parameters, set_parameters] = useState({});
    const [puid, set_puid] = useState({});

    useEffect(() => {
        api_app_report_req(); 
    }, []); 
    
    const api_app_report_req = () => {
        WEBCALL("app_report_req", setLoading, setData, setError, uid, 
            {
                "eportname": reportname,
                "parameters": parameters,
                //"multiscode": ""
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_storepinfo = () => {
        WEBCALL("app_storepinfo", setLoading, setData, setError, uid, 
            {
                "productid": puid
            }, 
            (data) => {}, 
            (error) => {}
        );
    };


    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <JMT_FIELD_HEADER title="Via Media Rewards Products"/>



            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}