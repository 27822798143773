import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 

export const POCKET = ({ pageSetter, uid, profile_data }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState({});


    useEffect(() => {
        api_app_pocket_statement(); 
    }, []); 

    
    const api_app_pocket_statement = () => {
        WEBCALL("app_pocket_statement", setLoading, setData, setError, uid, 
            {
                lastid: 0
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    const api_app_pocket_view = () => {
        WEBCALL("app_pocket_view", setLoading, setData, setError, uid, 
            {
                lastid: 0
            }, 
            (data) => {}, 
            (error) => {}
        );
    };

    return (
        <JMT_LOADING_PANEL loading={loading} data={data} error={error}>
            <div style={{ width:'100%', borderRadius: '15px', overflow: 'hidden' }}>
                <DataTable 
                    value={data?.app_pocket_statementlist} 
                    showHeader={false} 
                    style={{ borderRadius: '15px' }}
                    onRowClick={(e) => {pageSetter(e.data.onClick);}} 
                >
                    <Column field="icon" body={(rowData) => {return <img width="50px" src={rowData.icon} />}}/>
                    <Column field="title" />
                </DataTable>
            </div>
            <pre>data: {JSON.stringify(data, null, "\t")}</pre>
        </JMT_LOADING_PANEL>
    );
}