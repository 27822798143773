import React from 'react';

import { Button } from 'primereact/button';

export const JMT_FIELD_BUTTON = ({ caption, onclick, show=true }) => {

    return (
        <div style={{padding:'2px'}} className="col-12 md:col-12">
            <br/>
            <center>
                {
                    show === true
                    ?
                    <Button 
                        label={caption}
                        style={{ width:'100%', height:'50px', backgroundColor:'#008FE0', color:'white' }} 
                        className="p-button-rounded p-button-raised p-button-sm p-button-primary mr-2 mb-2" 
                        onClick={onclick}
                    />
                    :
                    <></>
                }
            </center>
        </div>
    );
}

