import React, { useState, useEffect } from 'react';

import { JMT_FIELD_HEADER } from '../../components/jmt_field_header'; 
import { JMT_LOADING_PANEL } from '../../components/jmt_loading_panel'; 
import { WEBCALL } from '../../components/webcall'; 
import { JMT_FIELD_TEXTBOX } from '../../components/jmt_field_textbox'; 
import { JMT_FIELD_PASSBOX } from '../../components/jmt_field_passbox'; 
import { JMT_FIELD_BUTTON } from '../../components/jmt_field_button'; 

export const LOGIN = ({ pageSetter, setUID, profile_data }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState({});

    const [username, setUsername] = useState("0123456789");
    const [password, setPassword] = useState("test123");

    // useEffect(() => {
    //     // handleLoad(); 
    // }, []); 
    const uid = "";
    const handleLoad = () => {
        WEBCALL("app_login", setLoading, setData, setError, uid, 
        {
            d: "",
            sn: "",
            username: username,
            cardnumber: "",
            password: password,
            version: "1074",
        }, 
            (data) => {
                setUID(data?.session)
                pageSetter("HOME")
            }, 
            (error) => {}
        );
    };


    return (
        <div style={{height:'100vh',backgroundColor:'white'}}>
            <div className="grid" style={{ padding:'0px'}}>
                <div className="col-12 lg:col-4" style={{ padding:'0px'}}>
                </div>
                <div className="col-12 lg:col-4">
                    <div style={{ padding:'0px', margin:'-5px' }}>
                        <img width="100%" src="/images/login_header.png" alt="Logo" />
                        <div style={{padding:'50px'}}>
                                Welcome back to TSPC
                                <JMT_FIELD_TEXTBOX id="Username" caption="Username" value={username} setter={setUsername}/>
                                <JMT_FIELD_PASSBOX id="Password" caption="Password" value={password} setter={setPassword}/>
                                <a href="#" onclick={handleLoad}>Forgot Password</a>
                                <JMT_FIELD_BUTTON caption="Login" onclick={handleLoad} />
                                Dont have an account? <a href="#">Register</a>
                                {/* <pre>data: {JSON.stringify(data, null, "\t")}</pre> */}

                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-4" style={{ padding:'0px'}}>
                </div>
            </div>
        </div>            

    );
}