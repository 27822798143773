import React from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


import { PrimeIcons } from 'primereact/api';
 

export const ACCOUNT = ({ pageSetter, uid, profile_data }) => {

    const menu = [
        {title: "Pocket",               icon: PrimeIcons.INBOX,      onClick: "POCKET"},
        // {title: "Transactions",         icon: PrimeIcons.MONEY_BILL, onClick: "TRANSACTIONS"},
        // {title: "CART",                 icon: "pi pi-shopping-cart", onClick: "CART"},
        // {title: "Transfers",            icon: PrimeIcons.ARROWS_H,   onClick: "TRANSFERS"},
        {title: "CellC Special Offers", icon: "pi pi-gift",          onClick: "CELLC_OFFERS"},
        {title: "Sim Stock Check",      icon: "pi pi-mobile",        onClick: "SIM_STOCK_CHECK"},
        {title: "Games",                icon: "pi pi-discord",       onClick: "GAMES"},
    ];
    const color =["#047a37", "#0387c9", "#FFCB05", "#D4171F"];      

    return (
            <div style={{ width:'100%', borderRadius: '15px', overflow: 'hidden' }}>
                <DataTable 
                    value={menu} 
                    showHeader={false} 
                    style={{ borderRadius: '15px' }}
                    onRowClick={(e) => {pageSetter(e.data.onClick);}} 
                >
                    <Column field="icon" body={(rowData) => {return <i className={rowData.icon}></i>}} />
                    <Column field="title" />
                </DataTable>
                {/* <pre>data: {JSON.stringify(menu, null, "\t")}</pre> */}
            </div>
    );
}